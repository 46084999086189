/** @format */

@import '@styles/variables';
@import '@styles/mixin';
.boxOverley {
	@include boxOverley;
}
.btnCustom {
	@include btnCustom;
}

.form-control {
	@include form-control;
}

.navnewclass {
	.header {
		top: -41px;
		&.checkoutHeader {
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			top: 0;
		}
	}
}
.searchOpenFilter {
	.headSearchBlock {
		display: $d-block !important;
	}
}
.advanceOpenFilter {
	.advanceSearchBlock {
		display: $d-block !important;
	}
}
.header {
	background-color: $white;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;

	@media screen and (max-width: $custom_md) {
		position: inherit;
	}
	@include transition(all 0.3s ease);
	.topHead {
		background-color: $orange;
		height: 41px;
		position: $relative;
		// @media screen and (max-width: $custom_md) {
		//   height: auto;
		// }
		@media screen and (max-width: $custom_md) {
			display: none;
		}
		p {
			padding: 0;
			font-size: $font14;
			color: $white;
			text-align: $text-center;
			line-height: 41px;
			font-family: $base-font;
			@media screen and (max-width: $custom_md) {
				line-height: 22px;
				padding: $pad10 - 1 $pad30;
			}
		}
	}

	.medialHead {
		height: 100px;
		position: $relative;
		@media screen and (max-width: $custom_md) {
			height: 60px;
		}
		.leftHdBlock {
			width: 33.333%;
			@media screen and (max-width: $custom_sm) {
				width: auto;
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;
			}
			.searchBox {
				@media screen and (max-width: $custom_md) {
					display: none;
				}
				span {
					cursor: $pointer;
					font-size: $font16 - 2;
					text-transform: $uppercase;
					font-weight: $font-semibold;
					font-family: $base-font;
					color: $black;
					@media screen and (max-width: $custom_xl) {
						font-size: $font14;
					}
					i {
						font-size: $font18;
						margin-right: 7px;
					}
				}
			}
			.currency_selectBox {
				padding-left: 2px;
				margin-left: 5px;
				border: none;
				background: none;
				outline: none;
				option {
					width: 100%;
					padding: 20px;
				}
			}
		}
		.medialHdBlock {
			width: 33.333%;
			@media screen and (max-width: $custom_sm) {
				width: auto;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
			}
			.logo {
				max-width: 200px;
				display: block;
				margin: 0 auto;
				@media screen and (max-width: $custom_xs) {
					max-width: 140px;
				}
				@media screen and (max-width: 350px) {
					max-width: 110px;
				}
				span {
					display: block !important;
				}
				img {
					width: 100% !important;
					display: block;
				}
			}
		}
		.rightHdBlock {
			width: 33.333%;
			@media screen and (max-width: $custom_sm) {
				width: auto;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
			.signRight {
				.selectCharity {
					display: flex;
					font-family: $base-font;
					font-size: $font14;
					.charityName {
						font-size: $font12 !important;
						font-weight: 400;
						text-transform: none;
						cursor: initial;
					}
					a {
						font-size: $font14;
						padding: 0 0 0px 5px;
						text-transform: inherit;
						font-weight: $font-medium;
						text-transform: none;
						color: $lightPink;
						padding-left: 1px;
					}
				}
				li {
					margin-left: $mrg30;
					position: $relative;
					@media screen and (max-width: $custom_xl) {
						margin-left: $mrg25;
					}
					@media screen and (max-width: $custom_xmd) {
						margin-left: $mrg10;
					}
					&.selectGoodCause {
						margin: 0;
						font-family: $base-font-six;
						font-size: $font15;
						color: $black;
						padding-right: $pad25;
						padding-top: $pad20;
						span {
							font-family: 'Poppins', sans-serif;
							font-weight: 600;
							font-size: 14px;
							text-transform: uppercase;
							position: relative;
							display: block;
							color: #000;
							cursor: pointer;
						}

						@media screen and (max-width: $custom_md) {
							display: none;
						}
						@media screen and (max-width: $custom_xl) {
							padding-right: $pad10;

							a {
								font-size: $font12;
							}
						}
					}
					&.dropNav {
						> span {
							font-size: $font14;
							div {
								top: 0px;
							}
						}
						@media screen and (max-width: $custom_md) {
							display: none;
						}
					}
					&.searchMobile {
						span {
							cursor: pointer;
							color: $black;
							i {
								@media screen and (max-width: $custom_md) {
									font-size: 18px;
									position: relative;
									top: 1px;
									right: 0;
								}
							}
						}
					}
					&.searchMobile {
						display: none;
						@media screen and (max-width: $custom_md) {
							display: block;
							position: $relative;
							top: -3px;
						}
					}
					@media screen and (max-width: $custom_md) {
						margin-left: $mrg10;
					}
					a {
						font-family: $base-font;
						font-weight: $font-semibold;
						font-size: $font16;
						text-transform: $uppercase;
						position: $relative;
						padding: $pad20 0;
						display: $d-block;
						@media screen and (max-width: $custom_xl) {
							font-size: $font14;
						}
						i {
							font-size: $font22;
							position: $absolute;
							top: 28px;
							right: -20px;
							img {
								width: 12px;
							}
						}
					}
					img {
						width: 24px;
						display: block;
					}
					&:hover {
						.openSubNav {
							display: $d-block;
						}
					}
					.openSubNav {
						position: $absolute;
						background: rgba(0, 0, 0, 0.85);
						min-width: 163px;
						left: 0;
						top: 100%;
						z-index: 9;
						padding: $pad10 0;
						border-radius: 3px;
						display: $d-none;
						box-shadow: 0 0 30px 0 #ccc;
						&::before {
							position: absolute;
							content: '';
							left: 20px;
							top: -12px;
							border-left: 11px solid transparent;
							border-right: 11px solid transparent;
							border-bottom: 13px solid rgba(0, 0, 0, 0.85);
							z-index: -1;
						}
						li {
							margin: 0;
							padding: 0;
							a {
								color: $white;
								letter-spacing: 1px;
								padding: $pad5 $pad10;
								font-size: $font14 - 3;
								display: $d-block;
								font-family: $base-font;
								font-weight: $font-medium;
								line-height: 1;
								&:hover {
									background-color: $overcast;
									color: $white;
								}
							}
						}
					}
					.wishlistIcon {
						cursor: pointer;
						background: none;
						border: none;
						width: 24px;
						height: 24px;
						position: $relative;
						top: -3px;
					}
					&:last-child {
						margin-left: $mrg15;
						@media screen and (max-width: $custom_xl) {
							margin-left: $mrg30;
						}
						@media screen and (max-width: $custom_md) {
							margin-left: $mrg10;
						}
					}
				}
			}
		}
	}
	.headSearchBlock {
		background: $lightBrown;
		display: block;
		position: $relative;
		display: none;
		@media screen and (max-width: $custom_md) {
			display: block;
		}
		.container {
			max-width: 585px;
			padding-top: $pad20;
			padding-bottom: $pad20;
			@media screen and (max-width: $custom_md) {
				padding-top: $pad5;
				padding-bottom: $pad5;
			}
		}
		.categorieSearch {
			position: $relative;
			@include bdr-rds($radius: 50px);
			background-color: $white;

			.categorySearchInner {
				position: relative;
				z-index: 10;
				.loaderPosition {
					position: absolute;
					top: 5px;
				}
				.searchElementBtn {
					position: absolute;
					top: 13px;
					left: 25px;
					background: none;
					border: none;
					cursor: pointer;
					padding: 0;
				}

				.desktop {
					padding: 0 15px 0 50px;
					display: none;
					border: solid 1px $blackNew;
					@media screen and (max-width: $custom_md) {
						display: block;
					}
					@media screen and (max-width: $custom_xs) {
						display: none;
					}
					border-radius: 50px;
					height: 50px;
					line-height: 50px;
					outline: none;
					box-shadow: none;
					font-size: 16px;
					font-weight: 400;
					color: $blackNew;
					&::placeholder {
						color: #a8a8a8;
						opacity: 1;
					}
					&:-ms-input-placeholder {
						color: #a8a8a8;
					}
					&::-ms-input-placeholder {
						color: #a8a8a8;
					}
				}
				.mobile {
					display: none;
					border: solid 1px $blackNew;
					@media screen and (max-width: $custom_xs) {
						padding: 0 15px 0 50px;
						display: block;
					}
					border-radius: 50px;
					height: 50px;
					line-height: 50px;
					outline: none;
					box-shadow: none;
					font-size: 16px;
					font-weight: 400;
					color: $blackNew;
					&::placeholder {
						color: #a8a8a8;
						opacity: 1;
					}
					&:-ms-input-placeholder {
						color: #a8a8a8;
					}
					&::-ms-input-placeholder {
						color: #a8a8a8;
					}
				}
			}
			.form-control {
				border: none;
				padding-right: $pad60 * 2 + 20;
			}
			.btnCustom {
				position: $absolute;
				top: 4px;
				right: 4px;
				padding: 0 $pad25;
				width: 130px;
				background-color: $lightBrown;
				color: $blackNew;
				.loaderPosition {
					position: absolute;
					left: 3px;
				}
				i {
					font-size: $font14 - 2;
					padding-right: $pad5;
				}
			}
		}
		.fillterSearchOpen {
			position: absolute;
			top: 20px;
			left: 0;
			right: 0;
			padding: 50px 15px 15px;
			background: #fff;
			z-index: 9;
			border-radius: 10px 10px 0 0;
			max-height: initial;
			overflow: hidden;
			border: 1px solid #d2d2d2;
			.searchBlock {
				overflow: scroll;
				min-height: 100px;
				max-height: 300px;
			}
			.listSearchCtg {
				padding-bottom: 20px;
				h4 {
					font-size: 14px;
					font-weight: 600;
					padding: 0;
					margin-bottom: 3px;
				}
				ul {
					padding: 0 10px;
					li {
						border-bottom: 1px solid #f5f5f5;
						&:last-child {
							border-bottom: 1px solid #fff;
						}
						a {
							display: inline-block;
							font-size: 13px;
							color: #000;
							padding: 3px 0;
						}
					}
				}
			}
			.btnCustom {
				position: static;
				width: 100%;
			}
		}

		.advanceSearchHd {
			padding: $pad15 0 $pad10;
			text-align: right;
			@media screen and (max-width: $custom_md) {
				display: none;
			}
			span {
				font-weight: $font-semibold;
				font-size: $font14;
				color: $black;
				cursor: $pointer;
				i {
					font-size: $font20;
					position: $relative;
					top: 3px;
					margin-left: $mrg5 - 2;
				}
			}
		}
		.advanceSearchBlock {
			display: block;
			padding: $pad5 0 $pad10;
			.formGroup {
				margin-bottom: 0;
				select {
					@include select-box;
				}
			}
		}
	}
	.menuOuter {
		position: $relative;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		@media screen and (max-width: $custom_md) {
			display: none;
		}
		.navigation {
			> ul {
				flex-wrap: wrap;
				justify-content: center;
				> li {
					margin-right: $mrg50;
					cursor: $pointer;
					font-family: $base-font;
					font-weight: $font-semibold;
					font-size: $font16;
					color: $blackNew;
					display: $d-block;
					padding: $pad30 - 2 $pad0;

					@media screen and (max-width: $custom_xxl) {
						font-size: $font14;
						margin-right: $mrg30;
						padding: $pad15 $pad0;
					}
					@media screen and (max-width: $custom_xl) {
						margin-right: $mrg15;
					}
					&:hover {
						color: $lightPink;
					}
					.link_active {
						color: $lightPink;
					}
					.goodCauseLink {
						color: $lightPink;
						&:hover {
							color: $blackNew;
							.goodCauseLogo {
								display: none;
							}
							.goodCauseLogoHover {
								display: block;
							}
						}
					}
					> a {
						&:hover {
							color: $lightPink;
						}
					}
					&:last-child {
						margin: 0;
					}
					&.active {
						> a {
							color: $red;
						}
						.subMenu {
							display: block;
						}
					}
					// &:hover {
					.subMenu {
						display: block;
					}
					// }
					.goodCauseLogo {
						position: absolute;
						left: -8px;
					}
					.goodCauseLogoHover {
						position: absolute;
						left: -8px;
						display: none;
					}
				}
			}
		}
		.subMenu {
			position: $absolute;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 9;
			/*padding-bottom:$pad60 + 30;*/
			border-top: 1px solid #ddd;
			display: none;
			&:after {
				position: $absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				height: 100vh;
				background: rgba(0, 0, 0, 0.6);
				content: '';
				pointer-events: none;
			}
			.subMenuInner {
				background: $white;
				position: $relative;
				z-index: 1;
			}
			.navScrollBlock {
				/*max-width: 97%;
				border-radius: 10px;
    			box-shadow: 0px 2px 9px #0000001a;*/
				position: $relative;
				z-index: 1;
				overflow-y: scroll;
				max-height: 600px;
				margin: 0;
				display: flex;
				scrollbar-width: thin;
				scrollbar-color: #7f7f7f $white;
				@media screen and (max-width: $custom_xxl) {
					max-height: 450px;
				}
				&::-webkit-scrollbar {
					width: 12px;
				}
				&::-webkit-scrollbar-track {
					background: $white;
				}
				&::-webkit-scrollbar-thumb {
					background-color: #7f7f7f;
					border-radius: 20px;
					border: 3px solid $white;
				}
			}
			.leftMenuList {
				text-align: right;
				padding: $pad15 + 3 $pad20 $pad20 0;
				ul {
					li {
						padding: $pad5 0;
						a {
							font-size: $font14 - 2;
							color: $black;
							font-family: $base-font-six;
							text-transform: $uppercase;
							display: $d-block;
						}
					}
				}
			}
			.rightMenuList {
				padding: $pad20 $pad15;
				flex: 1;
			}
			.navOuterBlock {
				margin-bottom: $mrg10;
				.navHd {
					font-size: $font14;
					color: $black;
					font-family: $base-font-six;
					text-transform: $uppercase;
					display: $d-block;
					margin-bottom: $mrg5;
				}
				ul {
					li {
						padding: $pad5 - 2 0;
						a {
							font-size: $font14 - 2;
							color: $black;
							font-family: $base-font;
							display: $d-block;
							font-weight: $font-medium;
						}
					}
				}
			}
		}
	}
}
.menuImageOpen {
	.mobileMenuBlock {
		right: 0;
	}
	.boxOverley {
		display: $d-block;
	}
}

.mobileMenuBlock {
	position: fixed;
	top: 0;
	bottom: 0;
	right: calc(-100% - 54px);
	background-color: $white;
	z-index: 99;
	width: 70%;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
	padding: $pad15 0 $pad10 $pad10;
	overflow: auto;
	@include transition(all 0.3s ease);
	.closeBlock {
		position: $relative;
		right: calc(-100% + 40px);
		display: flex;
		width: 80%;
	}
	.logoPosition {
		margin: 10px 0px;
		text-align: center;
	}
	.mobileNavList {
		padding: 0 $pad20 $pad10;
		ul {
			li {
				.goodCauseLink {
					color: $lightPink;
					position: relative;
					display: flex;

					&:hover {
						color: $blackNew;
						.goodCauseLogo {
							display: none;
						}
						.goodCauseLogoHover {
							display: block;
						}
					}
					.goodCauseLogoHover {
						display: none;
						padding-right: 5px;
					}
					.goodCauseLogo {
						padding-right: 5px;
					}
				}
				font-family: $base-font-six;
				span {
					display: $d-block;
					font-size: $font14;
					color: $black;
					text-transform: capitalize;
					position: $relative;
					padding: $pad10 - 0.5 0;
					i {
						padding-right: $pad5;
					}
				}
				a,
				p,
				span {
					display: $d-block;
					font-size: $font14;
					color: $black;
					text-transform: capitalize;
					position: $relative;
					padding: $pad10 - 0.5 0;
					&:hover {
						color: initial;
					}
					&[aria-expanded='false'] {
						&::before {
							position: $absolute;
							content: '\f105';
							right: 0;
							top: 0;
							color: #000;
							font-family: $font-awesome;
							line-height: 40px;
							font-size: $font20 - 2;
							font-weight: 900;
						}
					}
					&[aria-expanded='true'] {
						&::before {
							content: '\f107';
							position: $absolute;
							right: 0;
							top: 0;
							color: #000;
							font-family: $font-awesome;
							line-height: 40px;
							font-size: $font20 - 2;
							font-weight: 900;
						}
					}
				}
				p {
					font-family: $base-font-six;
				}
				.mobileSubMenu {
					padding-left: $pad10;
					li {
						&.smallText {
							a,
							span {
								font-family: $base-font-six;
								font-size: $font14 - 1;
								text-transform: $uppercase;
							}
						}
						a,
						span {
							font-size: $font14 - 1;
							font-family: $base-font;
							font-weight: $font-semibold;
						}
					}
				}
			}
		}
	}
	.mobileBottom {
		padding: 0 $pad20;
		.signBtnMob {
			padding-bottom: $pad10;
			span {
				display: $d-block;
				background: $white;
				font-size: $font14;
				text-align: center;
				border: 1px solid $black;
				color: $black;
				font-family: $base-font-three;
				border-radius: 4px;
				padding: $pad10 + 1 0;
			}
			&.registerBtn {
				padding-bottom: $pad15;
				span {
					background-color: $white;
					color: $black;
				}
			}
		}
		.countryList {
			position: $relative;
			p {
				font-size: 12px;
				padding-bottom: 2px;
			}
			.gridContainer {
				display: grid;
				grid-template-columns: 20px auto;
				gap: 10px;
				font-size: 12px;
				padding: 2px;
			}
			.styleOutside {
				width: 90%;
				background-color: #dedede85;
				padding: 5px !important;
				.locationLodingPos {
					position: $absolute;
					top: -5px;
					left: 15px;
				}
				span {
					top: 3px;
				}
			}
			a,
			span {
				font-size: $font14 - 1;
				display: $d-block;
				color: $black;
				font-weight: $font-semibold;
				position: $relative;
				margin-bottom: $mrg10;

				i {
					font-size: 14px;
					color: $grey;
					margin-right: 5px;
				}
				&[aria-expanded='false'] {
					&::before {
						position: $absolute;
						// content: '\f105';
						right: 0;
						top: -10px;
						color: #000;
						font-family: $font-awesome;
						line-height: 40px;
						font-size: $font20 - 2;
						font-weight: 900;
					}
				}
				&[aria-expanded='true'] {
					&::before {
						// content: '\f107';
						position: $absolute;
						right: 0;
						top: -10px;
						color: #000;
						font-family: $font-awesome;
						line-height: 40px;
						font-size: $font20 - 2;
						font-weight: 900;
					}
				}
			}
			ul {
				position: $absolute;
				top: -55px;
				left: 20px;
				background: $black;
				min-width: 200px;
				padding-left: 10px;
				padding-right: 2px;
				border-radius: 5px;
				box-shadow: 0 0 30px 0 $grey;
				li {
					padding: $pad10 0;
					span {
						margin-bottom: 0;
						color: $white;
						font-weight: 500;
					}
				}
			}
		}
	}
}

.checkoutHeader {
	.medialHead {
		.rightHdBlock {
			.signRight {
				li {
					&.myNameTp {
						@media screen and (max-width: $custom_md) {
							display: none;
						}
					}
				}
			}
		}
	}
}

.loggedHeader {
	.continueShoppingTg {
		a {
			font-size: $font14;
			font-weight: $font-semibold;
			i {
				margin-right: $mrg10;
				display: inline-block;
				@media screen and (max-width: $custom_md) {
					position: $relative;
					top: -4px;
				}
			}
			@media screen and (max-width: $custom_md) {
				font-size: 0;
			}
			span {
				display: none;
				@media screen and (max-width: $custom_md) {
					display: inline-block;
					font-size: $font14;
				}
			}
		}
	}
	.callHead {
		display: $d-block;
		text-align: $text-right;
		a {
			font-size: $font14;
			font-weight: $font-semibold;
			i {
				margin-right: $mrg10;
				@media screen and (max-width: $custom_md) {
					position: $relative;
					top: -4px;
					margin-right: 0;
				}
			}
			@media screen and (max-width: $custom_md) {
				font-size: 0;
			}
		}
	}
}

.searchOpenFilter {
	.menuOuter {
		.subMenu {
			.navScrollBlock {
				max-height: 500px;
				@media screen and (max-width: $custom_xxl) {
					max-height: 240px;
				}
			}
		}
	}
}
.wishlistCount,
.cartNumber {
	position: $absolute;
	left: 0;
	margin: 0 auto;
	font-size: $font14 - 4;
	text-align: center;
	width: 20px;
	background-color: $orange;
	border-radius: 10px;
	color: $white;
}
.wishlistCount {
	top: -7px;
	right: -25px;
}
.cartNumber {
	top: 15px;
	right: -20px;
}
/*au-nz-stores-menu*/
.auNzStoreMainInner {
	max-width: 930px;
	margin: 0 auto;
	padding: 40px 0;

	.storesImgBox {
		position: $relative;
		cursor: pointer;
		.imageWrapper {
			position: $relative;
			width: 100%;
			height: 250px;

			display: inline-block;
		}
		h2 {
			position: $absolute;
			right: 0;
			left: 0;
			text-align: center;
			top: 25px;
			color: $white;
			font-weight: bold;
			font-size: 40px;
			z-index: 1;
			a {
				color: $white;
			}
		}

		@media only screen and (max-width: $custom_sm) {
			h2 {
				font-size: 30px;
			}
		}
	}

	.storesText {
		display: inline-block;
		width: 100%;
		color: $black;
		line-height: normal;
		text-align: center;
		margin-top: 20px;
		font-family: $base-font;
		span {
			font-size: $font22;
			text-align: center;
			font-weight: $font-semibold;
			display: inline-block;
			margin: 0 auto;
			width: 100%;
		}
		a {
			color: $black;
			width: 100%;
			display: inline-block;
			line-height: 32px;
		}
	}
}

.shadow_block {
	border-radius: 10px;
	box-shadow: 0px 2px 9px #0000001a;
	margin: 0px 21px;
	width: 97%;
}
.headerScrollFix {
	top: -41px;
}

.topHeader {
	padding: 0 60px;
	height: 70px;
	display: flex;
	align-items: center;
	ul {
		width: 100%;
		li {
			width: 33.333%;
			font-size: 16px;
			font-weight: 500;
			color: $blackNew;
		}
	}
	@media screen and (max-width: $custom_md) {
		display: none;
	}
	@media screen and (max-width: 1480px) {
		padding: 0 15px;
		height: 40px;
		ul {
			li {
				font-size: 14px;
			}
		}
	}
}
.pistachioBg {
	background-color: #e5d8c9;
}

.headerMedial {
	padding: 20px 60px;
	position: relative;
	.rightHdBlock {
		width: 33.333%;
		@media screen and (max-width: $custom_sm) {
			width: auto;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
		.signRight {
			.selectCharity {
				display: flex;
				font-family: $base-font;
				font-size: $font14;
				.charityName {
					font-size: $font12 !important;
					font-weight: 400;
					text-transform: none;
					cursor: initial;
				}
				a {
					font-size: $font14;
					padding: 0 0 0px 5px;
					text-transform: inherit;
					font-weight: $font-medium;
					text-transform: none;
					color: $lightPink;
					padding-left: 1px;
				}
			}
			li {
				margin-left: $mrg30;
				position: $relative;
				@media screen and (max-width: $custom_xl) {
					margin-left: $mrg25;
				}
				@media screen and (max-width: $custom_xmd) {
					margin-left: $mrg10;
				}
				&.selectGoodCause {
					margin: 0;
					font-family: $base-font-six;
					font-size: $font15;
					color: $black;
					padding-right: $pad25;
					padding-top: $pad20;
					span {
						font-family: 'Poppins', sans-serif;
						font-weight: 600;
						font-size: 14px;
						text-transform: uppercase;
						position: relative;
						display: block;
						color: #000;
						cursor: pointer;
					}

					@media screen and (max-width: $custom_md) {
						display: none;
					}
					@media screen and (max-width: $custom_xl) {
						padding-right: $pad10;

						a {
							font-size: $font12;
						}
					}
				}
				&.dropNav {
					> span {
						font-size: $font14;
						div {
							top: 0px;
						}
					}
					@media screen and (max-width: $custom_md) {
						display: none;
					}
				}
				&.searchMobile {
					span {
						cursor: pointer;
						color: $black;
						i {
							@media screen and (max-width: $custom_md) {
								font-size: 18px;
								position: relative;
								top: 1px;
								right: 0;
							}
						}
					}
				}
				&.searchMobile {
					display: none;
					@media screen and (max-width: $custom_md) {
						display: block;
						position: $relative;
						top: -3px;
					}
				}
				@media screen and (max-width: $custom_md) {
					margin-left: $mrg10;
				}
				a {
					font-family: $base-font;
					font-weight: $font-semibold;
					font-size: $font16;
					text-transform: $uppercase;
					position: $relative;
					padding: $pad20 0;
					display: $d-block;
					@media screen and (max-width: $custom_xl) {
						font-size: $font14;
					}
					i {
						font-size: $font22;
						position: $absolute;
						top: 28px;
						right: -20px;
						img {
							width: 12px;
						}
					}
				}
				img {
					width: 24px;
					display: block;
				}
				&:hover {
					.openSubNav {
						display: $d-block;
					}
				}
				.openSubNav {
					position: $absolute;
					background: rgba(0, 0, 0, 0.85);
					min-width: 163px;
					left: 0;
					top: 100%;
					z-index: 9;
					padding: $pad10 0;
					border-radius: 3px;
					display: $d-none;
					box-shadow: 0 0 30px 0 #ccc;
					&::before {
						position: absolute;
						content: '';
						left: 20px;
						top: -12px;
						border-left: 11px solid transparent;
						border-right: 11px solid transparent;
						border-bottom: 13px solid rgba(0, 0, 0, 0.85);
						z-index: -1;
					}
					li {
						margin: 0;
						padding: 0;
						a {
							color: $white;
							letter-spacing: 1px;
							padding: $pad5 $pad10;
							font-size: $font14 - 3;
							display: $d-block;
							font-family: $base-font;
							font-weight: $font-medium;
							line-height: 1;
							&:hover {
								background-color: $overcast;
								color: $white;
							}
						}
					}
				}
				.wishlistIcon {
					cursor: pointer;
					background: none;
					border: none;
					width: 24px;
					height: 24px;
					position: $relative;
					top: -3px;
				}
				&:last-child {
					margin-left: $mrg15;
					@media screen and (max-width: $custom_xl) {
						margin-left: $mrg30;
					}
					@media screen and (max-width: $custom_md) {
						margin-left: $mrg10;
					}
				}
			}
		}
	}
	.leftHdBlock {
		position: absolute;
	}
	.logo {
		width: 450px;
		.logoImage {
			width: 312px;
		}
	}
	.searchHeaderBlock {
		max-width: 550px;
		flex: 0 0 550px;
		position: relative;
		z-index: 10;
		.loaderPosition {
			position: absolute;
			top: 5px;
		}
		.searchElementBtn {
			position: absolute;
			top: 13px;
			left: 25px;
			background: none;
			border: none;
			cursor: pointer;
			padding: 0;
		}
		.searchInpt {
			border: solid 2px #12141a;
			border-radius: 50px;
			height: 50px;
			line-height: 50px;
			outline: none;
			box-shadow: none;
			padding: 0 15px 0 50px;
			width: 100%;
			font-size: 16px;
			color: $blackNew;
			&::placeholder {
				color: #a8a8a8;
				opacity: 1;
			}
			&:-ms-input-placeholder {
				color: #a8a8a8;
			}
			&::-ms-input-placeholder {
				color: #a8a8a8;
			}
		}
		.fillterSearchOpen {
			position: absolute;
			top: 20px;
			left: 0;
			right: 0;
			padding: 50px 15px 15px;
			background: #fff;
			z-index: -1;
			border-radius: 10px 10px 0 0;
			max-height: initial;
			overflow: hidden;
			border: 1px solid #d2d2d2;
			.searchBlock {
				overflow: scroll;
				min-height: 100px;
				max-height: 300px;
			}
			.listSearchCtg {
				padding-bottom: 20px;
				h4 {
					font-size: 14px;
					font-weight: 600;
					padding: 0;
					margin-bottom: 3px;
				}
				ul {
					padding: 0 10px;
					li {
						border-bottom: 1px solid #f5f5f5;
						&:last-child {
							border-bottom: 1px solid #fff;
						}
						a {
							display: inline-block;
							font-size: 13px;
							color: #000;
							padding: 3px 0;
						}
					}
				}
			}
			.btnCustom {
				position: static;
				width: 100%;
				background-color: $lightBrown;
				color: $blackNew;
			}
		}
	}
	.headerRightBlock {
		width: 450px;
		display: flex;
		justify-content: flex-end;
		ul {
			li {
				margin-left: 20px;
				position: relative;
				font-size: 16px;
				font-weight: 600;
				color: #12141a;
				cursor: pointer;
				.userNameClass {
					display: block;
					padding: 10px 0;
				}
				a {
					color: #12141a;
				}
				img {
					display: block;
				}
				.img_arrow {
					font-size: $font22;
					position: $relative;
					top: 0px;
					right: -4px;
					display: $inline-block;
					width: 12px;
					height: 10px;
				}
				&:hover {
					.openSubNav {
						display: $d-block;
					}
				}
				.openSubNav {
					position: $absolute;
					background: rgba(0, 0, 0, 0.85);
					min-width: 163px;
					left: 0;
					top: 100%;
					z-index: 9;
					padding: $pad10 0;
					border-radius: 3px;
					display: $d-none;
					box-shadow: 0 0 30px 0 #ccc;
					&::before {
						position: absolute;
						content: '';
						left: 20px;
						top: -12px;
						border-left: 11px solid transparent;
						border-right: 11px solid transparent;
						border-bottom: 13px solid rgba(0, 0, 0, 0.85);
						z-index: -1;
					}
					li {
						margin: 0;
						font-size: $font14 - 3;
						color: $white;
						letter-spacing: 1px;
						padding: $pad5 $pad10;
						text-transform: uppercase;
						&:hover {
							background-color: $overcast;
							color: $white;
							a {
								color: $white;
							}
						}
						a {
							color: $white;
							display: $d-block;
							font-family: $base-font;
							font-weight: $font-medium;
							line-height: 1;
						}
					}
				}
				&.selectGoodCause {
					margin: 0;
					font-size: $font16;
					color: $black;
					font-weight: 600;
					bottom: -12px;
					.selectCharity {
						display: $d-block;
						.charityName {
							font-size: $font14 - 2;
							font-weight: 400;
						}
						a {
							color: $peach;
							font-size: $font14 - 2;
							font-weight: 400;
						}
					}

					@media screen and (max-width: $custom_md) {
						display: none;
					}
					@media screen and (max-width: $custom_xl) {
						padding-right: $pad10;

						a {
							font-size: $font12;
						}
					}
				}
			}
			.bagIcons {
				width: 30px;
				display: flex;
				justify-content: end;
				align-items: center;
			}
		}
		.searchElement {
			display: none;
		}
		.shopingCart {
			padding: 0 3px;
			position: absolute;
			top: -5px;
			right: -12px;
			min-width: 22px;
			height: 22px;
			border-radius: 20px;
			text-align: center;
			background: #8ebdc1;
			font-size: 15px;
			line-height: 22px;
		}
		@media only screen and (max-width: $custom_sm) {
			.signElement {
				display: none;
			}
		}
	}
	/** @format */
}
@media screen and (max-width: 1480px) {
	.headerMedial {
		padding: 20px 15px;
		.logo {
			width: 360px;
		}
		.headerRightBlock {
			width: 360px;
			padding-right: 10px;
			ul {
				li {
					margin-left: 15px;
					font-size: 14px;
					img {
						width: 26px;
					}
					.openSubNav {
						li {
							font-size: $font14 - 3;
						}
					}
				}
			}
			.shopingCart {
				right: -10px;
				min-width: 20px;
				height: 20px;
				border-radius: 20px;
				font-size: 12px;
				line-height: 20px;
				&.shopingCartOption {
					top: -7px;
				}
			}
		}

		.searchHeaderBlock {
			max-width: 400px;
			flex: 0 0 400px;
		}
	}
}
@media screen and (max-width: 1199px) {
	.logo {
		width: 190px;
	}
	.headerRightBlock {
		width: 190px;
		ul {
			li {
				img {
					width: 24px;
				}
			}
		}
	}

	.searchHeaderBlock {
		max-width: 400px;
		flex: 0 0 400px;
		.searchInpt {
			height: 46px;
			line-height: 46px;
			padding: 0 10px 0 40px;
			font-size: 13px;
		}
		.searchElementBtn {
			left: 15px;
		}
	}
}

@media screen and (max-width: 991px) {
	.logo {
		width: 160px;
		margin-left: 40px;
	}
	.signElement {
		display: none;
	}
	.headerMedial {
		padding: 15px;
		.searchHeaderBlock {
			display: none;
		}
		.headerRightBlock {
			width: 160px;
			ul {
				li {
					margin-left: 10px;
					.userNameClass {
						display: none;
					}
					&:first-child {
						margin-left: 0;
					}
					img {
						width: 20px;
					}
				}
			}
			.searchElement {
				display: block;
				span {
					i {
						font-size: 18px;
						line-height: 2;
					}
				}
			}
		}
	}

	.searchHeaderBlock {
		max-width: 300px;
		flex: 0 0 300px;
	}
}

@media screen and (max-width: 767px) {
	.headerMedial {
		padding: 15px;
		.searchHeaderBlock {
			display: none;
		}
	}

	.headeRightBlock {
		width: auto;
		ul {
			li {
				.searchElement {
					a {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.menuLogo {
	width: 240px !important;
	margin-left: 20px !important;
	text-align: left !important;
	.logoImage {
		width: 190px;
	}
}

.mobSortOverlay {
	top: -100%;
	height: 1000vh;
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	width: 100%;
	z-index: 999;
	left: 0;
}
.mobSortContents {
	left: 0;
	position: fixed;
	width: 100vw;
	background: #fff;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	bottom: 0;
	z-index: 99999;
	padding: 20px 0;
	@media screen and (max-width: $custom_md) {
		display: block;
		transform: translateY(0);
		height: 25%;
		min-height: 25%;
		max-height: 25%;
	}
	@media screen and (max-width: $custom_xsm) {
		height: 35%;
		min-height: 35%;
		max-height: 350%;
	}
	@media screen and (max-width: $custom_xs) {
		height: 40%;
		min-height: 40%;
		max-height: 40%;
	}

	.closeButtonSort {
		background: 0 0;
		border: 0;
		position: absolute;
		right: 25px;
	}
	.outer {
		margin-top: 40px;
		padding: 0 15px;
		h6 {
			font-size: $font16;
			margin: 0 10px;
			padding: 0 15px;
			line-height: 1.3;
			@media screen and (max-width: $custom_xss) {
				font-size: $font14;
			}
			@media screen and (max-width: $custom_exs) {
				font-size: $font12;
			}
		}
		.mButtom {
			margin-bottom: 25px;
			margin-top: 15px;
		}
		.margin {
			margin: 0 10px;
		}
		.formGroup {
			width: 50%;
			display: flex;
			margin: auto;
		}
	}
}
